import React, { useState, useCallback, useContext } from 'react';

import { useQuery } from 'react-query';

import {
  PAYMENTS_SWITCH_OPTIONS,
  ACCOUNT_LINK_TYPES,
} from '../../constants/main';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import PaymentService from '../../services/PaymentService';
import UserService from '../../services/UserService';
import useUser from '../../hooks/useUser';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import Card from '../../components/Payments/Card';
import PaymentHeader from '../../components/Payments/PaymentHeader';
import Switch from '../../components/Toolbar/Switch';
import BillingHistoryList from '../../components/Payments/BillingHistoryList';
import Pagination from '../../components/Pagination';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';

const PaymentsPage = () => {
  const { isServiceProvider, isCustomer, currentUser } = useUser();
  const { showErrorModal } = useContext(UiContext);

  const [selectedBillingHistoryType, setSelectedBillingHistoryType] = useState(
    isCustomer
      ? PAYMENTS_SWITCH_OPTIONS.PAYMENTS
      : PAYMENTS_SWITCH_OPTIONS.GETTING_PAID
  );
  const [currentPage, setCurrentPage] = useState(1);

  const handleSwitchChange = useCallback((type) => {
    setSelectedBillingHistoryType(type);
  }, []);
  const {
    data: cardDetails,
    refetch,
    isFetched: isCardFetched,
  } = useQuery('card', PaymentService.getCardDetails, {
    retry: false,
    enabled: isCustomer,
  });
  const { refetch: reFetchCurrentUserData } = useQuery(
    'currentUser',
    UserService.getMe,
    {
      staleTime: Infinity,
      manual: true,
      retry: 0,
    }
  );
  const { data: billingHistory } = useQuery(
    ['paymentLogs', currentPage, selectedBillingHistoryType],
    () =>
      PaymentService.getPaymentLogs({
        page: currentPage,
        limit: 7,
        type: selectedBillingHistoryType,
      }),
    {
      keepPreviousData: true,
    }
  );
  const handleCardSubmit = async (stripeToken) => {
    try {
      await PaymentService.createCustomer(stripeToken);
      refetch();
      reFetchCurrentUserData();
    } catch (error) {
      console.log(error);
    }
  };
  const handleCardUpdate = async (stripeToken) => {
    try {
      await PaymentService.updateCard(stripeToken);
      refetch();
    } catch (error) {
      showErrorModal({ message: error.message });
      console.log(error);
    }
  };
  const profileLink = async () => {
    try {
      if (isServiceProvider) {
        try {
          const accountData = await PaymentService.createConnectedAccountLink(
            currentUser.detailsSubmitted
              ? ACCOUNT_LINK_TYPES.LOGIN
              : ACCOUNT_LINK_TYPES.ONBOARD
          );
          window.open(accountData.url, '_black');
        } catch (error) {
          showErrorModal({ message: error.message });
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePageChange = useCallback((event) => {
    const { selected } = event;

    window.scrollTo(0, 0);
    setCurrentPage(selected + 1);
  }, []);
  return (
    <div className={classes.PaymentsPage}>
      <HeaderMenuContainer headerTitle="Billing & Payments" isUserBadgeVisible>
        <h1 className={classes.title}>Billing & Payments</h1>
        <div className={classes.innerContainer}>
          <div className={classes.billingHistoryContainer}>
            <div className={classes.billingHistoryHeader}>
              <Switch
                customerOption={PAYMENTS_SWITCH_OPTIONS.PAYMENTS}
                providerOption={PAYMENTS_SWITCH_OPTIONS.GETTING_PAID}
                selectedOption={selectedBillingHistoryType}
                onChange={handleSwitchChange}
                userRoles={{ isCustomer, isServiceProvider }}
              />
              {isServiceProvider &&
                selectedBillingHistoryType ===
                  PAYMENTS_SWITCH_OPTIONS.GETTING_PAID && (
                  <div className={classes.profileButtonsContainer}>
                    {!currentUser.detailsSubmitted && (
                      <PrimaryButton
                        onClick={profileLink}
                        iconName="arrow"
                        classnames={[classes.profileButton]}
                      >
                        FINISH ONBOARDING
                      </PrimaryButton>
                    )}
                    <PrimaryButton
                      disabled={!currentUser.detailsSubmitted}
                      onClick={profileLink}
                      iconName="arrow"
                      classnames={[classes.profileButton]}
                    >
                      DASHBOARD
                    </PrimaryButton>
                  </div>
                )}
            </div>
            {isServiceProvider &&
              selectedBillingHistoryType ===
                PAYMENTS_SWITCH_OPTIONS.GETTING_PAID && <PaymentHeader />}

            {isCustomer &&
              selectedBillingHistoryType ===
                PAYMENTS_SWITCH_OPTIONS.PAYMENTS && (
                <p className={classes.paymentInformation}>
                  Any hours logged by a service provider in an active engagement
                  will be billed directly to the card on file every Monday. Once
                  payment is successfully processed, a receipt will be emailed
                  to you and can also be downloaded here for your records.
                </p>
              )}

            {billingHistory?.paymentLogs?.length > 0 ? (
              <BillingHistoryList items={billingHistory?.paymentLogs} />
            ) : (
              <p className={classes.noBillingHistory}>
                You have no{' '}
                {selectedBillingHistoryType === PAYMENTS_SWITCH_OPTIONS.PAYMENTS
                  ? 'billing '
                  : 'payout '}
                history yet.{' '}
                {isCardFetched &&
                  !cardDetails &&
                  selectedBillingHistoryType ===
                    PAYMENTS_SWITCH_OPTIONS.PAYMENTS &&
                  'Please add the card to make payments.'}
              </p>
            )}
          </div>
          <div>
            <div className={classes.creditContainer}>
              <div>
                <span className={classes.availableCredit}>
                  Available Credit: &nbsp;
                </span>
                <span
                  className={`
        ${currentUser.credits === 0 ? classes.availableCredit : classes.credit}
      `}
                >
                  ${currentUser.credits}
                </span>
              </div>
              <p className={classes.creditDescription}>
                Any available credit will be applied to all billing first. If no
                credit is available, the card on file will be used.
              </p>
            </div>

            {isCustomer &&
              selectedBillingHistoryType ===
                PAYMENTS_SWITCH_OPTIONS.PAYMENTS && (
                <Card
                  cardDetails={cardDetails}
                  onSubmit={handleCardSubmit}
                  onUpdate={handleCardUpdate}
                  className={classes.cardContainer}
                />
              )}
          </div>
        </div>
        {isCustomer && (
          <Pagination
            pageCount={billingHistory?.pageCount}
            handlePageChange={handlePageChange}
          />
        )}
      </HeaderMenuContainer>
    </div>
  );
};

export default PaymentsPage;
