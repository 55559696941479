import React, { useRef, useCallback } from 'react';

import cn from 'classnames';

import { RiDeleteBin6Line } from 'react-icons/ri';

import cameraIcon from '../../../assets/icons/camera.svg';
import uploadIcon from '../../../assets/icons/pen-white.svg';

import RoundButtonWithIcon from '../Buttons/RoundButtonWithIcon';
import classes from './styles.module.scss';

const AvatarUpload = ({
  isProjectManager,
  avatarPreview,
  handleAvatarUploadChange,
  handleAvatarDeleteClick,
}) => {
  const uploadRef = useRef(null);

  const handleAvatarUploadClick = useCallback(() => {
    uploadRef.current.click();
  }, []);

  return (
    <div className={classes.AvatarUpload}>
      <img
        src={avatarPreview || cameraIcon}
        alt="Avatar"
        className={cn(classes.uploadedContent, {
          [classes.avatar]: avatarPreview,
        })}
      />
      {!isProjectManager && (
        <RoundButtonWithIcon
          onClick={handleAvatarUploadClick}
          icon={uploadIcon}
          iconAlt="Upload"
          classnames={[classes.uploadButton]}
        />
      )}

      {!isProjectManager && avatarPreview && !!handleAvatarDeleteClick && (
        <RoundButtonWithIcon
          onClick={handleAvatarDeleteClick}
          iconElement={<RiDeleteBin6Line size={20} />}
          iconAlt="Delete profile picture"
          classnames={[classes.deleteButton]}
        />
      )}

      <input
        onChange={handleAvatarUploadChange}
        ref={uploadRef}
        type="file"
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default AvatarUpload;
